<template>
  <div>
    <HeaderNavBarOrder
      :back="true"
      :title="$t('track_your_order')"
      title-style="text-center text-lg"
    />

    <div class="mt-14 mb-20 w-full items-center justify-center">
      <div class="no-scrollbar container overflow-y-auto" :class="deviceWidth">
        <div v-if="orderDetailIsLoaded">
          <div
            v-if="
              ['confirm', 'ready', 'done', 'cancel'].includes(
                activeOrder.status
              )
            "
            class="text-ink-dark flex justify-center bg-gray-50 py-8 px-2"
          >
            <div
              v-if="activeOrder.status === 'confirm'"
              class="flex w-2/12 flex-col items-center"
            >
              <OrderConfirmedIcon class="text-ink-primary" />
              <p class="text-ink-primary mt-2 text-center text-xs">
                {{ $t('tracking_status_confirmed') }}
              </p>
            </div>
            <div v-else class="flex w-2/12 flex-col items-center">
              <OrderConfirmedIcon class="text-gray-300" />
              <p class="mt-2 text-center text-xs text-gray-300">
                {{ $t('tracking_status_confirmed') }}
              </p>
            </div>
            <div class="flex w-1/12 flex-col items-center">
              <div class="mt-4 w-full border border-gray-300"></div>
            </div>
            <div
              v-if="
                activeOrder.transaction.status === 'process' &&
                activeOrder.status !== 'ready' &&
                activeOrder.status !== 'done'
              "
              class="flex w-2/12 flex-col items-center"
            >
              <OrderOnProcessIcon class="text-ink-primary" />
              <p class="text-ink-primary mt-2 text-center text-xs">
                {{ $t('tracking_status_on_progress') }}
              </p>
            </div>
            <div v-else class="flex w-2/12 flex-col items-center">
              <OrderOnProcessIcon class="text-gray-300" />
              <p class="mt-2 text-center text-xs text-gray-300">
                {{ $t('tracking_status_on_progress') }}
              </p>
            </div>
            <div class="flex w-1/12 flex-col items-center">
              <div class="mt-4 w-full border border-gray-300"></div>
            </div>
            <div
              v-if="activeOrder.status === 'ready'"
              class="flex w-2/12 flex-col items-center"
            >
              <OrderDeliverIcon class="text-ink-primary w-8" />
              <p class="text-ink-primary mt-2 text-center text-xs">
                <span v-if="!activeOrder.tenant_is_pickup">
                  {{ $t('ready_delivered') }}
                </span>
                <span v-else> {{ $t('ready_to_pickup') }}</span>
              </p>
            </div>
            <div v-else class="flex w-2/12 flex-col items-center">
              <OrderDeliverIcon class="w-8 text-gray-300" />
              <p class="mt-2 text-center text-xs text-gray-300">
                <span v-if="!activeOrder.tenant_is_pickup">
                  {{ $t('ready_delivered') }}
                </span>
                <span v-else> {{ $t('ready_to_pickup') }}</span>
              </p>
            </div>
            <div class="flex w-1/12 flex-col items-center">
              <div class="mt-4 w-full border border-gray-300"></div>
            </div>
            <div
              v-if="
                activeOrder.transaction.status === 'completed' ||
                activeOrder.status === 'done'
              "
              class="flex w-2/12 flex-col items-center"
            >
              <OrderDoneIcon class="text-ink-primary" />
              <p class="text-ink-primary mt-2 text-center text-xs">
                {{ $t('tracking_status_completed') }}
              </p>
            </div>
            <div
              v-else-if="activeOrder.status !== 'cancel'"
              class="flex w-2/12 flex-col items-center"
            >
              <OrderDoneIcon class="text-gray-300" />
              <p class="mt-2 text-center text-xs text-gray-300">
                {{ $t('tracking_status_completed') }}
              </p>
            </div>
            <div
              v-else-if="activeOrder.status === 'cancel'"
              class="flex w-2/12 flex-col items-center"
            >
              <OrderDoneIcon class="text-ink-danger" />
              <p class="text-ink-danger mt-2 text-center text-xs">
                <span v-if="activeOrder.cancel_by">
                  {{ $t(`cancelled_by_${activeOrder.cancel_by}`) }}
                </span>
                <span v-else>
                  {{ $t('tracking_status_cancelled') }}
                </span>
              </p>
            </div>
            <div v-else class="flex w-2/12 flex-col items-center">
              <OrderDoneIcon class="text-gray-300" />
              <p class="mt-2 text-center text-xs text-gray-300">
                <span v-if="activeOrder.cancel_by">
                  {{ $t(`cancelled_by_${activeOrder.cancel_by}`) }}
                </span>
                <span v-else>
                  {{ $t('tracking_status_cancelled') }}
                </span>
              </p>
            </div>
          </div>
          <div
            v-else
            class="align-center bg-ink-light text-ink-dark flex w-full flex-col justify-between"
          >
            <div
              class="flex flex-col border-b-4 border-b-gray-200 pt-8 pb-12 text-center"
            >
              <template
                v-if="
                  activeOrder.transaction.status === 'pending' ||
                  (activeOrder.status === 'pending' &&
                    activeOrder.paid_at === null)
                "
              >
                <p class="text-md text-ink-warning">
                  {{ $t('tracking_complete_your_payment') }}
                </p>
                <p
                  v-if="activeOrder.transaction.payment_due_date !== null"
                  class="mt-2 text-sm text-gray-400"
                >
                  {{ $t('tracking_complete_your_payment_desc') }}
                  {{
                    hoursMinutesFromDate(
                      activeOrder.transaction.payment_due_date
                    )
                  }}
                </p>
              </template>
              <template
                v-else-if="
                  activeOrder.status === 'pending' &&
                  activeOrder.paid_at !== null
                "
              >
                <p class="text-md text-ink-warning">
                  {{ $t('tracking_waiting_confirmation_title') }}
                </p>
                <p class="mt-2 text-sm text-gray-400">
                  {{ $t('tracking_waiting_confirmation_desc') }}
                  {{
                    hoursMinutesFromDate(activeOrder.created_at, {
                      num: 30,
                      type: 'm',
                    })
                  }}
                </p>
              </template>
            </div>
          </div>
          <div
            class="align-center bg-ink-light text-ink-dark flex w-full flex-col justify-between"
          >
            <div class="text-ink-dark my-2 px-6 py-3">
              <div class="mb-4 flex justify-between">
                <h2
                  class="text-md w-full cursor-pointer text-ellipsis font-semibold line-clamp-2"
                  @click="
                    $router.push({
                      name: 'menu',
                      params: {
                        slug: $route.params.slug,
                        tenantId: activeOrder.tenant_id,
                      },
                      query: {
                        lang: $route.query.lang,
                      },
                    })
                  "
                >
                  {{ activeOrder.tenant_name }}
                </h2>
                <div class="flex w-full items-center justify-end">
                  <div
                    class="flex items-center rounded py-1 px-3"
                    :class="[
                      !activeOrder.tenant_is_pickup
                        ? 'bg-ink-green-opacity border-r-2 border-r-successgreen text-successgreen'
                        : 'bg-ink-yellow-opacity border-r-2 border-r-warningyellow text-warningyellow',
                    ]"
                  >
                    <p class="flex items-center space-x-1 text-xs font-bold">
                      <template v-if="!activeOrder.tenant_is_pickup">
                        <span>
                          {{ $t('delivery_label') }}
                        </span>
                        <InfoIcon
                          v-tooltip.bottom="{
                            content: $t('delivery_label_desc'),
                            theme: 'info-tooltip',
                          }"
                          class="inline w-4"
                        />
                      </template>
                      <template v-else>
                        <span>
                          {{ $t('pickup_label') }}
                        </span>
                        <InfoIcon
                          v-tooltip.bottom="{
                            content: $t('pickup_label_desc'),
                            theme: 'info-tooltip',
                          }"
                          class="inline w-4"
                        />
                      </template>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-ink-dark mb-2 -mt-6 flex flex-col px-6 pb-4">
              <!-- GET TRANSACTION DETAIL -->
              <section v-if="activeOrder.status !== 'cancel'">
                <div
                  v-for="(item, index) in transactionDetail"
                  :key="item.id + index"
                  class="w-full"
                >
                  <div class="mt-4 flex flex-row space-x-2">
                    <div class="h-max w-14 flex-none items-center self-center">
                      <div class="flex justify-center">
                        <ImageLoader
                          :image="item.product_image"
                          height="h-14"
                        />
                      </div>
                    </div>
                    <div class="flex w-full flex-row justify-between">
                      <div class="flex w-full flex-col justify-start">
                        <p class="text-sm font-semibold leading-7">
                          {{ item.quantity }} x
                          {{ item.product_name }}
                        </p>
                        <p
                          v-if="item.additional !== null"
                          class="text-xs text-gray-400 line-clamp-1"
                        >
                          {{ arrayToString(item.additional, 'name') }}
                        </p>
                        <p
                          v-if="item.note !== null && item.note !== ''"
                          class="text-justify text-xs text-gray-400 line-clamp-3"
                        >
                          <span class="font-semibold text-black">
                            {{ $t('note') }}:
                          </span>
                          {{ item.note }}
                        </p>
                      </div>
                      <div class="flex justify-start">
                        <p class="mt-1 text-sm text-gray-600 line-clamp-1">
                          <Currency
                            :total-in-string="
                              item.paid_price_after_markup.toString()
                            "
                          />
                        </p>
                      </div>
                    </div>
                    <div class="w-max flex-none self-center">
                      <div class="flex justify-end">
                        <!-- <i class="las la-angle-down la-lg text-ink-dark font-bold"></i> -->
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <!-- GET TRANSACTION HISTORY -->
              <section v-if="activeOrder.status === 'cancel'">
                <div
                  v-for="(item, index) in transactionHistory"
                  :key="item.id + index"
                  class="w-full"
                >
                  <div class="mt-4 flex flex-row space-x-2">
                    <div class="h-max w-14 flex-none items-center self-center">
                      <div class="flex justify-center">
                        <ImageLoader
                          :image="item.product_image"
                          height="h-14"
                        />
                      </div>
                    </div>
                    <div class="flex w-full flex-row justify-between">
                      <div class="flex w-full flex-col justify-start">
                        <p class="text-sm font-semibold leading-7">
                          {{ item.quantity }} x
                          {{ item.product_name }}
                        </p>
                        <p
                          v-if="item.additional !== null"
                          class="text-xs text-gray-400 line-clamp-1"
                        >
                          {{ arrayToString(item.additional, 'name') }}
                        </p>
                        <p
                          v-if="item.note !== null && item.note !== ''"
                          class="text-justify text-xs text-gray-400 line-clamp-3"
                        >
                          <span class="font-semibold text-black">
                            {{ $t('note') }}:
                          </span>
                          {{ item.note }}
                        </p>
                      </div>
                      <div class="flex justify-start">
                        <p class="mt-1 text-sm text-gray-600 line-clamp-1">
                          <Currency
                            :total-in-string="
                              item.paid_price_after_markup.toString()
                            "
                          />
                        </p>
                      </div>
                    </div>
                    <div class="w-max flex-none self-center">
                      <div class="flex justify-end">
                        <!-- <i class="las la-angle-down la-lg text-ink-dark font-bold"></i> -->
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div
              v-if="totalShowMore > 0"
              class="w-max flex-none cursor-pointer self-center"
            >
              <div
                class="flex items-center space-x-2 pt-2 pb-4 text-xs"
                @click="showActiveOrderChild = !showActiveOrderChild"
              >
                <template v-if="showActiveOrderChild">
                  <span>{{ $t('showLess') }}</span>
                  <ArrowUpIcon class="w-4" />
                </template>
                <template v-else>
                  <span>{{
                    $t('showMore', {
                      total: totalShowMore,
                    })
                  }}</span>
                  <ArrowDownIcon class="w-4" />
                </template>
              </div>
            </div>

            <div
              v-if="hasDetailSum"
              class="align-center bg-ink-light text-ink-dark flex w-full flex-col justify-between"
            >
              <div class="flex w-full flex-col px-6 py-3">
                <div class="flex w-full flex-row justify-between">
                  <div class="flex w-full">
                    <span class="text-sm text-gray-400">{{ $t('price') }}</span>
                  </div>
                  <div class="flex w-full justify-end">
                    <span class="text-sm font-bold">
                      <Currency
                        :total-in-string="
                          detailSum.total_price_after_markup.toString()
                        "
                      />
                    </span>
                  </div>
                </div>
                <div
                  class="flex w-full flex-row justify-between border-b-2 border-b-gray-200 py-3"
                >
                  <div class="flex w-full">
                    <span class="text-sm text-gray-400">{{
                      $t('discount')
                    }}</span>
                  </div>
                  <div class="flex w-full justify-end text-gray-400">
                    <Currency
                      wrap-class="text-sm font-bold text-ink-danger"
                      prefix-text="-"
                      :total-in-string="detailSum.discount.toString()"
                    />
                  </div>
                </div>
                <div
                  v-if="detailSum.cashback"
                  class="flex w-full flex-row justify-between border-b-2 border-b-gray-200 py-3"
                >
                  <div class="flex w-full">
                    <span class="text-sm text-gray-400">{{
                      $t('cashback')
                    }}</span>
                  </div>
                  <div class="flex w-full justify-end text-gray-400">
                    <Currency
                      wrap-class="text-sm font-bold text-ink-success"
                      prefix-text="(+"
                      :total-in-string="detailSum.cashback.toString()"
                      leading-text=")"
                    />
                  </div>
                </div>
                <div
                  class="flex w-full flex-row justify-between border-b-2 border-b-gray-200 py-3"
                >
                  <div class="flex w-full">
                    <span
                      v-if="activeOrder.transaction.status === 'pending'"
                      class="text-sm text-gray-400"
                      >{{ $t('total') }} {{ $t('with_fee') }}</span
                    >
                    <span v-else class="text-sm text-gray-400">{{
                      $t('total')
                    }}</span>
                  </div>
                  <div class="flex w-full flex-col justify-end">
                    <div class="flex justify-end">
                      <span class="text-sm font-bold">
                        <Currency
                          :total-in-string="totalPrice"
                          :copy-text="totalPrice"
                          @copy="
                            onCopy({
                              text: totalPrice,
                            })
                          "
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex w-full flex-row justify-between py-3">
                  <div class="flex w-full">
                    <span class="text-sm text-gray-400">
                      {{ $t('youWillGet') }}
                    </span>
                  </div>
                  <div class="flex w-full justify-end">
                    <span class="text-ink-warning text-sm font-bold">
                      {{
                        $t('totalPoints', {
                          total:
                            activeOrder.transaction.point_gained.toString(),
                        })
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="flex w-full flex-col px-6 py-2">
                <div class="flex w-full">
                  <span class="text-md font-bold">{{
                    $t('tracking_order_details')
                  }}</span>
                </div>
                <div
                  class="flex w-full flex-row justify-between border-b-2 border-b-gray-200 py-3"
                >
                  <div class="flex w-full">
                    <span class="text-sm text-gray-400">{{
                      $t('id_order')
                    }}</span>
                  </div>
                  <div class="flex w-full justify-end">
                    <span class="order-id text-sm font-bold"
                      >#{{
                        activeOrder.transaction_id.split('-')[0].toUpperCase()
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="flex w-full flex-row justify-between border-b-2 border-b-gray-200 py-3"
                >
                  <div class="flex w-full">
                    <span class="text-sm text-gray-400">{{
                      $t('table_row')
                    }}</span>
                  </div>
                  <div class="flex w-full flex-col justify-end">
                    <div class="flex w-full justify-end">
                      <span class="text-sm font-bold">{{
                        activeOrder.transaction.table_name || '-'
                      }}</span>
                    </div>
                    <div
                      v-if="!['done', 'cancel'].includes(activeOrder.status)"
                      class="flex pt-1"
                    >
                      <div class="flex w-full justify-end">
                        <button
                          class="bg-ink-light text-ink-primary block rounded-lg py-2 pl-6 text-xs font-semibold"
                          @click="openModalTable(activeOrder.transaction)"
                        >
                          {{ $t('edit') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex w-full flex-row justify-between border-b-2 border-b-gray-200 py-3"
                >
                  <div class="flex w-full">
                    <span class="text-sm text-gray-400">{{
                      $t('payment_method')
                    }}</span>
                  </div>
                  <div class="flex w-full justify-end">
                    <span class="text-sm font-bold uppercase">{{
                      activeOrder.transaction.payment_type === 'ewallet'
                        ? activeOrder.transaction.payment_method
                        : activeOrder.transaction.payment_type
                    }}</span>
                  </div>
                </div>
                <div class="flex w-full flex-row justify-between py-3">
                  <div class="flex w-full">
                    <span class="text-sm text-gray-400">{{
                      $t('pickup_or_delivered')
                    }}</span>
                  </div>
                  <div class="flex w-full justify-end">
                    <span
                      v-if="!activeOrder.tenant_is_pickup"
                      class="text-sm font-bold"
                      >{{ $t('delivered') }}</span
                    >
                    <span v-else class="text-sm font-bold">{{
                      $t('pickup')
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                ['done', 'cancel'].includes(
                  switchIndxOrderStatus(activeOrder.status)
                )
              "
              class="mt-2 flex w-full px-6 pb-6"
            >
              <div
                v-if="
                  activeOrder.status === 'done' && isCanReviewOrder === true
                "
                class="flex w-full"
              >
                <div class="flex w-full pr-2">
                  <button
                    class="bg-ink-light text-ink-primary border-ink-primary w-full rounded border border-transparent px-4 py-3 focus:outline-none"
                    @click="openModalReviewOrder(activeOrder)"
                  >
                    {{ $t('tracking_review') }}
                  </button>
                </div>
                <div class="flex w-full pl-2">
                  <button
                    class="bg-ink-primary -darker border-ink-primary text-ink-light w-full rounded border border-transparent px-4 py-3 opacity-100 hover:opacity-90 focus:outline-none"
                    @click="repeatOrder(activeOrder.id)"
                  >
                    {{ $t('order_reorder_btn') }}
                  </button>
                </div>
              </div>
              <div v-else class="flex w-full">
                <button
                  class="bg-ink-primary -darker border-ink-primary text-ink-light w-full rounded border border-transparent px-4 py-3 opacity-100 hover:opacity-90 focus:outline-none"
                  @click="repeatOrder(activeOrder.id)"
                >
                  {{ $t('order_reorder_btn') }}
                </button>
              </div>
            </div>
            <div
              v-else-if="activeOrder.transaction.status === 'pending'"
              class="mt-2 flex px-6 pb-6"
            >
              <Button
                :title="$t('order_cancel_btn')"
                button-color="danger-outline"
                @click="openModalCancelOrder(activeOrder)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal change table row -->
    <Sheet
      v-model:visible="isModalTabelOpened"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="30%"
      max-height="70%"
    >
      <div
        class="no-scrollbar bg-ink-light h-full overflow-y-scroll px-4 pt-4 pb-52 md:mt-6"
      >
        <div
          class="flex select-none flex-wrap items-center justify-between text-xl font-medium leading-none"
        >
          <p class="text-xl font-bold">{{ $t('want_to_change_table_row') }}</p>
          <p class="pt-2 text-sm text-gray-500">
            {{ $t('input_table_row') }}
          </p>
        </div>

        <div class="mt-4 mb-2">
          <div class="flex flex-wrap">
            <div class="w-full pr-5 pl-2">
              <Multiselect
                ref="tableIdInput"
                v-model="tableId"
                class="w-full rounded-lg border-gray-100"
                :placeholder="$t('chooseTableRow')"
                :searchable="true"
                label="name"
                :options="tableList"
                @select="selectTableId"
              >
                <template #singlelabel="{ value }">
                  <div
                    class="multiselect-single-label flex w-full justify-between"
                  >
                    {{ value.name }}
                  </div>
                </template>

                <template #option="{ option }">
                  <div
                    class="character-option-icon flex w-full justify-between"
                  >
                    <span>{{ option.name }}</span>
                    <span
                      :class="[
                        !option.disabled
                          ? 'text-ink-success'
                          : 'text-ink-danger',
                      ]"
                      >{{ option.label }}</span
                    >
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-ink-light fixed bottom-0 flex w-full px-4 pb-4 pt-2">
        <Button
          :title="$t('edit_table_row')"
          button-color="primary"
          @click="changeTable()"
        />
      </div>
    </Sheet>

    <!-- Modal cancel order -->
    <Sheet
      v-model:visible="isModalCancelOrderOpened"
      :max-width="deviceWidth === 'w-phone' ? '480px' : '100%'"
      :min-height="deviceWidth === 'w-phone' ? '25%' : '35%'"
      max-height="80%"
    >
      <CancelOrder
        :order="orderCancel"
        @notify-cancel-order="notifyCancelOrder($event)"
      />
    </Sheet>

    <!-- Modal review order -->
    <Sheet
      v-model:visible="showReviewOrder"
      :max-width="deviceWidth == 'w-phone' ? '480px' : '100%'"
      min-height="40%"
      max-height="80%"
    >
      <ReviewOrder
        :order="reviewOrder"
        @notify-review-order="notifyReviewOrder($event)"
      />
    </Sheet>
  </div>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import orderMixin from '@/mixins/order.js'
import tableMixin from '@/mixins/table.js'

import { Sheet } from 'bottom-sheet-vue3'
import Multiselect from '@vueform/multiselect'

import HeaderNavBarOrder from '@/components/navbar/HeaderNavBarOrder.vue'
import Currency from '@/components/currency/Currency.vue'
import ImageLoader from '@/components/loader/ImageLoader.vue'
import Button from '@/components/button/Button.vue'
import CancelOrder from '@/components/order/CancelOrder.vue'
import ReviewOrder from '@/components/order/ReviewOrder.vue'

import ArrowUpIcon from '@/assets/svg/arrow-up.svg'
import ArrowDownIcon from '@/assets/svg/arrow-down.svg'
import OrderConfirmedIcon from '@/assets/svg/trolly-2.svg'
import OrderOnProcessIcon from '@/assets/svg/chef.svg'
import OrderDoneIcon from '@/assets/svg/check-circle.svg'
import OrderDeliverIcon from '@/assets/svg/deliver.svg'
import InfoIcon from '@/assets/svg/info.svg'
// import { socket } from '@/socket'

export default {
  name: 'OrderTenantPage',
  components: {
    Sheet,
    Multiselect,

    HeaderNavBarOrder,
    Currency,
    ImageLoader,

    ArrowUpIcon,
    ArrowDownIcon,

    OrderConfirmedIcon,
    OrderOnProcessIcon,
    OrderDoneIcon,
    OrderDeliverIcon,
    InfoIcon,

    Button,
    CancelOrder,
    ReviewOrder,
  },
  mixins: [globalMixin, orderMixin, tableMixin],
  inject: ['deviceWidth'],
  layout: 'main',
  data: () => ({
    showModal: false,
    activeOrder: [],
    transactionDetail: [],
    transactionHistory: [],

    orderDetailIsLoaded: false,
    showActiveOrderChild: false,
    orderParentIndex: 2,

    orders: [],

    servingTime: null,

    tableRow: null,
    activeOrderTable: {},
    isModalTabelOpened: false,

    timerCount: 0,

    isCanReviewOrder: false,
    showReviewOrder: false,
    reviewOrder: {},

    isModalCancelOrderOpened: false,
    orderCancel: [],
  }),
  computed: {
    isUpdateTenantStatus: {
      get() {
        return this.$store.getters['order/isUpdateTenantStatus']
      },
      set(status) {
        return this.$store.dispatch('order/setTransactionTenantNotify', status)
      },
    },
    isUpdateStatus: {
      get() {
        return this.$store.getters['order/isUpdateStatus']
      },
      set(status) {
        return this.$store.dispatch('order/setTransactionNotify', status)
      },
    },
    hasDetailSum() {
      return (
        this.activeOrder.transaction_detail_sum.length > 0 ||
        this.activeOrder.transaction_history_sum.length > 0
      )
    },
    detailSum() {
      let detailSum = {}
      if (
        this.activeOrder.status !== 'cancel' &&
        this.activeOrder.transaction_detail_sum.length > 0
      ) {
        detailSum = this.activeOrder.transaction_detail_sum[0]
      } else if (
        this.activeOrder.status === 'cancel' &&
        this.activeOrder.transaction_history_sum.length > 0
      ) {
        detailSum = this.activeOrder.transaction_history_sum[0]
      }
      return detailSum
    },
    totalPrice() {
      if (this.activeOrder.transaction.status === 'pending') {
        return this.activeOrder.transaction.payment_amount.toString()
      } else {
        return this.detailSum.total_price_after_markup.toString()
      }
    },
    totalShowMore() {
      return this.activeOrder.status === 'cancel'
        ? this.activeOrder.transaction_history.length - this.orderParentIndex
        : this.activeOrder.transaction_detail.length - this.orderParentIndex
    },
  },
  watch: {
    isUpdateTenantStatus(value) {
      if (value) {
        this.getOrderTenantDetail().then(() => {
          const messageAlert = {
            show: true,
            message: this.$t(`order_${value.status}`),
            type: 'info',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
      }
    },
    isUpdateStatus(value) {
      if (value) {
        this.getOrderTenantDetail().then(() => {
          const messageAlert = {
            show: true,
            message: this.$t(`order_${value.status}`),
            type: 'info',
            position: 'center',
          }
          this.$emit('showToast', messageAlert)
        })
      }
    },
  },
  watch: {
    showActiveOrderChild(val) {
      this.filterShowMore()
    },
  },
  mounted() {
    this.isUpdateTenantStatus = false
    this.isUpdateStatus = false
    this.initSocket()

    this.getLocalTableList()
    this.getOrderTenantDetail()
  },
  sockets: {
    connect: function () {
      // console.log('socket connected')
    },
  },
  methods: {
    filterShowMore() {
      if (this.showActiveOrderChild) {
        this.transactionDetail = this.activeOrder.transaction_detail
        this.transactionHistory = this.activeOrder.transaction_history
      } else {
        const transactionDetail = this.activeOrder.transaction_detail
        if (transactionDetail.length > 0) {
          this.transactionDetail = transactionDetail.slice(
            0,
            this.orderParentIndex
          )
        } else {
          this.transactionDetail = transactionDetail
        }

        const transactionHistory = this.activeOrder.transaction_history
        if (transactionHistory.length > 0) {
          this.transactionHistory = transactionHistory.slice(
            0,
            this.orderParentIndex
          )
        } else {
          this.transactionHistory = transactionHistory
        }
      }
    },
    initSocket() {
      // this.getStatusTenantTransaction(this.$route.params.orderId)
      // this.getStatusTransaction(this.$route.params.orderId)
    },
    // async getStatusTransaction(id) {
    //   socket.emit('transactionNotify', id)
    // },
    // async getStatusTenantTransaction(id) {
    //   socket.emit('transactionTenantNotify', id)
    // },
    async getOrderTenantDetail() {
      if (!this.isLoggedIn) {
        this.ordersIsLoaded = false
        return
      }
      this.$emit('showLoadingIndicator', true)
      await this.$store
        .dispatch('order/v2_detail_tenant', {
          id: this.$route.params.orderId,
        })
        .then((response) => {
          this.activeOrder = response.data

          if (this.activeOrder.transaction_detail.length > 0) {
            this.activeOrder.transaction_detail.map((order) => {
              if (order.review === null) {
                this.isCanReviewOrder = true
              }
            })
          } else if (this.activeOrder.transaction_history.length > 0) {
            this.activeOrder.transaction_history.map((order) => {
              if (order.review === null) {
                this.isCanReviewOrder = true
              }
            })
          }

          this.filterShowMore()

          this.orderDetailIsLoaded = true
          this.$emit('showLoadingIndicator', false)
        })
        .catch((e) => {
          if (this.orders.length === 0) {
            this.ordersIsLoaded = false
          }
          this.$emit('showLoadingIndicator', false)
        })
    },
    openModalTable(transaction) {
      this.activeOrderTable = transaction
      // this.tableRow = transaction.table
      this.tableId = transaction.table_id
      this.isModalTabelOpened = true
    },
    openModalCancelOrder(order) {
      this.isModalCancelOrderOpened = true
      this.orderCancel = order
    },
    notifyCancelOrder(back) {
      if (back) {
        this.$router.back()
      } else {
        this.isModalCancelOrderOpened = false
        this.getOrderTenantDetail()
      }
    },
    openModalReviewOrder(item) {
      this.reviewOrder = item
      this.showReviewOrder = true
    },
    notifyReviewOrder(reviewOrderCallback, refresh) {
      this.activeOrder = reviewOrderCallback

      let totalCanReview = 0
      this.activeOrder.transaction_detail.map((order) => {
        if (order.review === null) {
          totalCanReview += 1
        }
      })
      if (totalCanReview === 0) {
        this.isCanReviewOrder = false
      } else {
        this.isCanReviewOrder = true
      }

      if (refresh) {
        this.showReviewOrder = false
        this.getOrderTenantDetail()
      } else {
        this.showReviewOrder = false
      }
    },
  },
}
</script>
